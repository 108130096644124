import { createAction, props } from '@ngrx/store';
import { AuthUser } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum AuthActions {
  GetAuthenticatedUser = '[Auth] Get Authenticated User',
  GetAuthenticatedUserSuccess = '[Auth] Get Authenticated User Success',
  GetAuthenticatedUserFailure = '[Auth] Get Authenticated User Failure',
  GetAuth0Token = '[Auth] Get Auth0 Token',
  Login = '[Auth] Login',
  LoginRedirect = '[Auth] Login Redirect',
  LoginFailure = '[Auth] Login Failure',
  Logout = '[Auth] Logout',
  PromptLogin = '[Auth] Display Login Prompt',
}

/** Login */
export const login = createAction(AuthActions.Login);
export const loginRedirect = createAction(AuthActions.LoginRedirect, props<{ returnUrl: string }>());
export const loginFailure = createAction(AuthActions.LoginFailure, props<{ error: any }>());
export const logout = createAction(AuthActions.Logout);

/** Get Authenticated User */
export const getAuthenticatedUser = createAction(AuthActions.GetAuthenticatedUser);
export const getAuthenticatedUserSuccess = createAction(
  AuthActions.GetAuthenticatedUserSuccess,
  props<{ user: AuthUser }>()
);
export const getAuthenticatedUserFailure = createAction(
  AuthActions.GetAuthenticatedUserFailure,
  props<{ error: any }>()
);

/** Get Auth0 Token */
export const getAuth0Token = createAction(AuthActions.GetAuth0Token);

/** Auth return url */
export const promptLogin = createAction(AuthActions.PromptLogin);
